// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

.glow-on-hover {
    transition: all 0.3s ease;
    box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 7px #006aff, 0 0 10px #006aff, 0 0 12px #006aff, 0 0 15px #006aff, 0 0 17px #006aff;
}

.glow-on-hover:hover {
    box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 15px #006aff, 0 0 20px #006aff, 0 0 26px #006aff, 0 0 30px #006aff, 0 0 35px #006aff;
}

/* styles.css or your preferred CSS file */
.primary-card-header {
    background-color: #321fdb; /* Assuming primary is a CSS variable representing your primary color */
    color: white; /* Adjust text color if needed */
    cursor: pointer;
}

/* GenerateNew.css */
.float-right.spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.spin {
    animation: spin 1s linear infinite;
}


.indent {
    padding-left: 2em;
}

.indent_first_word {
    display: inline-block;  /* Convert the span to an inline-block so it respects margin/padding */
    margin-left: 1em;       /* Add left margin for the desired indentation */
}


.bold {
    font-weight: bold;
}

.intro {
    padding-left:2em;
    text-indent:3em;
    text-align: justify;
}

.indent {
    padding-left: 2em;
    text-align: justify;
    hyphens: auto;
}

.section {
    position: relative;
}

.section-number {
    position: absolute;
    left: 0;
}

.section-content {
    display: inline-block;
    vertical-align: top;  /* This ensures the content aligns with the top of the section number */
    width: calc(100% - 2em - 20px);  /* Adjusts the width to account for the margin and the width of the section number */
    margin-left: 2em;
}

.document_margins {
    margin-right: 1.5in;
    margin-left: 1.5in;
    font-family: "Times New Roman", Times, serif; /* Times New Roman is a classic choice for legal documents */
    font-size: "12pt"
}


